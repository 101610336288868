//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
      height: `100vh`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
    <h1 style={{ textAlign: `center`}}>Rowan Edward</h1>
    <h2 style={{ textAlign: `center`, fontSize: `14px`}}>Web Developer</h2>
    <span style={{ textAlign: `center`, fontSize: `14px`, display: `block`}}>&mdash;</span>
    <span className="location" style={{ textAlign: `center`}}>Melbourne + London</span>

    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
